import { useQuery } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from './use-purchase-service';

export function usePurchaseReverse(id: string, workspace?: string) {
    const context = useAppContext();
    const { service } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'purchase-reverse',
            {
                workspace: workspace ? workspace : context.workspace?.id,
                id: id,
            },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return {};

            return await service.reverse(context.workspace.id, id);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch purchase information: ${error.message}`,
            });
        },
    });
}
