import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { ApiServer } from './api.server';

export class LinkApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'links');
    }

    async link(item: string, orders: any) {
        console.log(`client<links>| link(): Enter`);
        console.log(`client<links>| link(): $item = ${item}`);
        console.log(`client<links>| link(): $data = ${JSON.stringify(orders)}`);

        return super.put('link', item, orders);
    }

    async get(workspace: string, item: string) {
        console.log(`client<links>| get(): Enter`);
        console.log(`client<links>| get(): $workspace = ${workspace}`);
        console.log(`client<links>| get(): $item = ${item}`);

        return super.get('get', workspace, item);
    }

    async delete(workspace: string, item: string, purchaseId: string) {
        console.log(`client<links>| delete(): Enter`);
        console.log(`client<links>| delete(): $workspace = ${workspace}`);
        console.log(`client<links>| delete(): $item = ${item}`);
        console.log(`client<links>| delete(): $purchaseId = ${purchaseId}`);

        return super.delete('delete', workspace, item, purchaseId);
    }

    async reset(workspace: string, item: string) {
        console.log(`client<links>| reset(): Enter`);
        console.log(`client<links>| reset(): $workspace = ${workspace}`);
        console.log(`client<links>| reset(): $item = ${item}`);

        return super.delete('reset', workspace, item);
    }

    async listBatch(body: { orderIds: (number | string)[] }) {
        console.log(`client<links>| listBatch(): Enter`);
        console.log(
            `client<links>| listBatch(): $body = ${JSON.stringify(body)}`,
        );

        return super.post('listBatch', body);
    }

    async getByPurchase(workspace: string, purchaseId: string) {
        console.log(`client<links>| getByPurchase(): Enter`);
        console.log(
            `client<links>| getByPurchase(): $workspace = ${workspace}`,
        );
        console.log(
            `client<links>| getByPurchase(): $purchaseId = ${purchaseId}`,
        );

        return super.get('getByPurchase', workspace, purchaseId);
    }
}
