import { SalesOrderSearchRequest } from '../../domain/models/sales-order.model';
import { ServiceConfigInterface } from './../../domain/interfaces/config.context.interface';
import { ApiServer } from './api.server';

export class OrderApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'orders', 'orders');
    }

    get(workspace: string, item: string) {
        console.log(`client<order>| get(): Enter`);
        console.log(`client<order>| get(): $workspace = ${workspace}`);
        return super.get('get', workspace, item);
    }

    list(workspace: string) {
        console.log(`client<order>| list(): Enter`);
        console.log(`client<order>| list(): $workspace = ${workspace}`);
        return super.get('list', workspace);
    }

    update(workspace: string, item: string, order: any) {
        console.log(`client<order>| update(): Enter`);
        console.log(`client<order>| update(): $workspace = ${workspace}`);
        console.log(`client<order>| update(): $item = ${item}`);
        console.log(
            `client<order>| update(): $order = ${JSON.stringify(order)}`,
        );
        return super.put('patch', workspace, item, order);
    }

    search(orders: number[]) {
        console.log(`client<order>| search(): Enter`);
        console.log(`client<order>| search(): $orders = ${orders}`);

        return super.post('search', orders);
    }

    attach(workspace: string, item: string, data: any) {
        console.log(`client<order>| attach(): Enter`);
        console.log(`client<order>| attach(): $workspace = ${workspace}`);
        console.log(`client<order>| attach(): $item = ${item}`);
        console.log(`client<order>| attach(): $data = ${JSON.stringify(data)}`);

        return super.put('attach', workspace, item, data);
    }

    getPo(workspace: string, item: string) {
        console.log(`client<order>| getPo(): Enter`);
        console.log(`client<order>| getPo(): $workspace = ${workspace}`);
        console.log(`client<order>| getPo(): $item = ${item}`);

        return super.get('getPo', workspace, item);
    }

    searchWithDate(workspace: string, request?: SalesOrderSearchRequest) {
        console.log(`client<order>| searchWithDate(): Enter`);
        console.log(
            `client<order>| searchWithDate(): $workspace = ${workspace}`,
        );
        console.log(
            `client<order>| searchWithDate(): $request = ${JSON.stringify(
                request,
            )}`,
        );

        return super.post('searchWithDate', workspace, request);
    }

    shipped(workspace: string, item: string, data: any) {
        console.log(`client<order>| shipped(): Enter`);
        console.log(`client<order>| shipped(): $workspace = ${workspace}`);
        console.log(`client<order>| shipped(): $item = ${item}`);
        console.log(
            `client<order>| shipped(): $data = ${JSON.stringify(data)}`,
        );

        return super.put('shipped', workspace, item, data);
    }

    batch(purchases: string[]) {
        console.log(`client<order>| batch(): Enter`);
        console.log(`client<order>| batch(): $purchases = ${purchases}`);

        return super.post('batch', purchases);
    }
}
