import { CreateCategoryParams } from '../../domain/models/category.model';
import { ServiceConfigInterface } from './../../domain/interfaces/config.context.interface';
import { ApiServer } from './api.server';

export class CategoryApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'products', 'category');
    }

    list(workspace: string) {
        console.log(`client<category>| list(): Enter`);
        console.log(`client<category>| list(): $workspace = ${workspace}`);
        return super.get('list', workspace);
    }

    listBase() {
        console.log(`client<category>| listBase(): Enter`);
        return super.get('listBase');
    }

    search(workspace: string, name: string) {
        console.log(`client<category>| search(): Enter`);
        console.log(`client<category>| search(): $workspace = ${workspace}`);
        console.log(`client<category>| search(): $name = ${name}`);

        return super.get('search', workspace, name);
    }

    searchByCode(workspace: string, code: string) {
        console.log(`client<category>| searchByCode(): Enter`);
        console.log(`client<category>| searchByCode(): $workspace = ${workspace}`);
        console.log(`client<category>| searchByCode(): $code = ${code}`);

        return super.get('searchByCode', workspace, code);
    }

    create(workspace: string, data: CreateCategoryParams) {
        console.log(`client<category>| create(): Enter`);
        console.log(`client<category>| create(): $workspace = ${workspace}`);
        console.log(
            `client<category>| create(): $data = ${JSON.stringify(data)}`,
        );
        return super.post('create', workspace, data);
    }
}
