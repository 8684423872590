import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RelationRequester } from '../../../../domain/enums/relation-requester.enum';
import { Workspace } from '../../../../domain/models/workspace.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePartnerService } from './use-partner-service';

export interface LinkProductMaterialParams {
    supplierWorkspace?: Workspace;
    partnerWorkspace?: Workspace;
    delegateWorkspace?: Workspace;
    isNominated?: boolean;
}

export function usePartnerConnect() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = usePartnerService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            partnerWorkspace,
            supplierWorkspace,
            delegateWorkspace,
            isNominated = false,
        }: LinkProductMaterialParams) => {
            if (!context.workspace?.id || !partnerWorkspace) return;

            return await service.create(
                supplierWorkspace || {},
                isNominated,
                partnerWorkspace,
                delegateWorkspace || {},
                RelationRequester.BUYER_CONNECT,
            );
        },
        onSuccess: (_, args) => {
            setTimeout(() => {
                client.invalidateQueries({
                    queryKey: ['partners'],
                });

                client.invalidateQueries({
                    queryKey: ['workspaces'],
                });

                client.invalidateQueries({
                    queryKey: ['delegations', 'list'],
                });
            }, 1500);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to create partner: ${error.message}`,
                title: 'Create Partner',
            });
        },
    });
}
