import { CategoryApiClient } from '../../infrastructure/clients/category.client';
import {
    CreateCategoryParams,
    ProductCategory,
} from '../models/category.model';
import { ServiceConfigInterface } from './../interfaces/config.context.interface';

/**
 * @class
 * @name CategoryService
 * @description The award service to get awards
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class CategoryService {
    private server: CategoryApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new CategoryApiClient(config, token);
    }

    async list(workspace: string) {
        console.log(`service<category>| list(): Enter`);
        console.log(`service<category>| list(): $workspace = ${workspace}`);
        const result: any[] = await this.server.list(workspace);
        return result.map((r) => this.convert(r));
    }

    async listBase() {
        console.log(`service<category>| listBase(): Enter`);
        const result: any[] = await this.server.listBase();
        return result.map((r) => this.convert(r));
    }

    async create(workspace: string, data: CreateCategoryParams) {
        console.log(`service<category>| create(): Enter`);
        console.log(`service<category>| create(): $workspace = ${workspace}`);
        console.log(`service<category>| create(): $data = ${data}`);
        return await this.server.create(workspace, data);
    }

    async searchByCode(workspace: string, code: string) {
        console.log(`service<category>| searchByCode(): Enter`);
        console.log(
            `service<category>| searchByCode(): $workspace = ${workspace}`,
        );
        console.log(`service<category>| searchByCode(): $code = ${code}`);
        const result: any = await this.server.searchByCode(workspace, code);
        return this.convert(result);
    }

    async search(workspace: string, name: string): Promise<ProductCategory[]> {
        console.log(`service<category>| search(): Enter`);
        console.log(`service<category>| search(): $workspace = ${workspace}`);
        console.log(`service<category>| search(): $name = ${name}`);

        return await this.server.search(workspace, name);
    }

    private convert(source: any) {
        console.log(`service<category>| convert(): Enter`);
        console.log(
            `service<category>| convert(): $source = ${JSON.stringify(source)}`,
        );

        const response = {
            id: source?.id,
            base: source?.base,
            code: source?.code,
            createdOn: source?.createdOn,
            description: source?.description,
            lastUpdatedOn: source?.lastUpdatedOn,
            name: source?.name,
            workspace: source?.workspace,
        };

        return response;
    }
}
