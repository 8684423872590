import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Supplier } from '../../../../domain/models/supplier.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from './use-purchase-service';

export interface CreatePurchaseParams {
    currency: string;
    externalDataId?: string;
    items: {
        material: string;
        comment: string;
        quantity: number;
        ppu: number;
        unit?: string;
    }[];
    rules: any;
    supplier: Supplier | string | undefined;
    supplierProducer?: string;
    purchaseProcesses?: string[];
    orderNature?: string;
    workspaceId?: string;
    delegated?: string;
    externalCustomerReference?: string;
    parentOrderReference?: string;
    programme?: string;
}

export function useCreatePurchase() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ workspaceId, ...args }: CreatePurchaseParams) => {
            if (!context.workspace?.id) return;

            return await service.create(
                workspaceId || context.workspace.id,
                args,
            );
        },
        onSuccess: (_, { workspaceId }) => {
            client.invalidateQueries({
                queryKey: [
                    'purchases',
                    { type: workspaceId || context.workspace?.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Purchase created successfully',
                title: 'Create Purchase',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to create purchase: ${error.message}`,
                title: 'Create Purchase',
            });
        },
    });
}
