import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePartner } from '../../../../infrastructure/hooks/api/partner/use-partner';
import PartnerDetailLayout from './partner-detail.layout';
import { useTranslation } from 'react-i18next';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';

interface PartnerDetailComponentProps {}

const PartnerDetailComponent: React.FC<PartnerDetailComponentProps> = () => {
    const { t } = useTranslation();
    const { id, delegateId } = useParams();
    const [menu, setMenu] = useState<'details' | 'documents'>('details');

    const { delegations } = useGetDelegations();
    const delegatedWorkspace = delegations?.find(
        (x) => x.id?.toString() === delegateId?.toString(),
    )?.workspace;

    const menuOptions = [
        { label: t('partner:detail.menu.details'), value: 'details' },
        { label: t('partner:detail.menu.documents'), value: 'documents' },
    ];

    const { data } = usePartner({
        id,
        delegatedWorkspace,
        enabled: !!delegatedWorkspace,
    });

    return (
        <PartnerDetailLayout
            data={data}
            menu={menu}
            setMenu={setMenu}
            menuOptions={menuOptions}
        />
    );
};

export default PartnerDetailComponent;
