import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from './use-purchase-service';

interface DeletePurchaseParams {
    id: string;
    delegateId?: string;
}

export function useDeletePurchase() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ id, delegateId }: DeletePurchaseParams) => {
            if (!context.workspace?.id || !id) return;

            await service.delete(delegateId || context.workspace.id, id);
        },
        onSuccess: (_, { delegateId, id }) => {
            client.invalidateQueries({
                queryKey: [
                    'purchase',
                    { workspace: delegateId || context.workspace?.id, id: id },
                ],
            });

            client.invalidateQueries({
                queryKey: [
                    'order',
                    { workspace: delegateId || context.workspace?.id, id: id },
                ],
            });

            client.invalidateQueries({
                queryKey: ['orderSearch'],
            });

            client.invalidateQueries({
                queryKey: ['purchases'],
            });

            client.invalidateQueries({
                queryKey: [
                    'purchaseTrace',
                    { workspace: delegateId || context.workspace?.id, id: id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Purchase deleted successfully',
                title: 'Delete Purchase',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to delete purchase: ${error.message}`,
                title: 'Delete Purchase',
            });
        },
    });
}
