import { LinkApiClient } from '../../infrastructure/clients/link.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';

/**
 * @class
 * @name LinkService
 * @description The link service for the application
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class LinkService {
    private server: LinkApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new LinkApiClient(config, token);
    }

    async link(item: string, orders: any) {
        console.log(`service<links>| link(): Enter`);
        console.log(`service<links>| link(): $item = ${item}`);
        console.log(
            `service<links>| link(): $data = ${JSON.stringify(orders)}`,
        );

        return await this.server.link(item, orders);
    }

    async get(workspace: string, item: string) {
        console.log(`service<links>| get(): Enter`);
        console.log(`service<links>| get(): $workspace = ${workspace}`);
        console.log(`service<links>| get(): $item = ${item}`);

        return await this.server.get(workspace, item);
    }

    async delete(workspace: string, item: string, purchaseId: string) {
        console.log(`service<links>| delete(): Enter`);
        console.log(`service<links>| delete(): $workspace = ${workspace}`);
        console.log(`service<links>| delete(): $item = ${item}`);
        console.log(`service<links>| delete(): $purchaseId = ${purchaseId}`);

        return await this.server.delete(workspace, item, purchaseId);
    }

    async reset(workspace: string, item: string) {
        console.log(`service<links>| reset(): Enter`);
        console.log(`service<links>| reset(): $workspace = ${workspace}`);
        console.log(`service<links>| reset(): $item = ${item}`);

        return await this.server.reset(workspace, item);
    }

    async listBatch(body: { orderIds: (number | string)[] }) {
        console.log(`service<links>| listBatch(): Enter`);
        console.log(
            `service<links>| listBatch(): $body = ${JSON.stringify(body)}`,
        );

        return await this.server.listBatch(body);
    }

    async getByPurchase(workspace: string, purchaseId: string) {
        console.log(`service<links>| getByPurchase(): Enter`);
        console.log(
            `service<links>| getByPurchase(): $workspace = ${workspace}`,
        );
        console.log(
            `service<links>| getByPurchase(): $purchaseId = ${purchaseId}`,
        );

        return await this.server.getByPurchase(workspace, purchaseId);
    }
}
