import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { Solicitation } from '../../domain/models/solicitation.model';
import { Workspace } from '../../domain/models/workspace.model';
import { ApiServer } from './api.server';

export class SolicitationApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'solicitation');
    }

    create(
        me: Workspace,
        isNominated: boolean,
        partner: any,
        delegateWorkspace: Workspace,
    ) {
        console.log(`client<solicition>| create(): Enter`);
        console.log(
            `client<solicition>| create(): $me = ${JSON.stringify(me)}`,
        );
        console.log(
            `client<solicition>| create(): $partner = ${JSON.stringify(
                partner,
            )}`,
        );
        console.log(
            `client<solicition>| create(): $isNominated = ${JSON.stringify(
                isNominated,
            )}`,
        );

        return super.post(
            'create',
            me.id,
            partner.company.id,
            delegateWorkspace.id,
            isNominated,
            undefined,
        );
    }

    invite(workspace: string, request: Solicitation) {
        console.log(`client<solicition>| create(): Enter`);
        console.log(
            `client<solicition>| create(): $workspace = ${JSON.stringify(
                workspace,
            )}`,
        );
        console.log(
            `client<solicition>| create(): $request = ${JSON.stringify(
                request,
            )}`,
        );
        return super.post('invite', workspace, request);
    }

    getDocuments(workspace: string, partner: string) {
        console.log(`client<solicition>| getDocuments(): Enter`);
        console.log(
            `client<solicition>| getDocuments(): $workspace = ${JSON.stringify(
                workspace,
            )}`,
        );
        console.log(
            `client<solicition>| getDocuments(): $partner = ${JSON.stringify(
                partner,
            )}`,
        );

        return super.get('documents', workspace, partner);
    }
}
