import { OrderApiClient } from '../../infrastructure/clients/order.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import {
    SalesOrder,
    SalesOrderSearchRequest,
} from './../models/sales-order.model';

/**
 * @class
 * @name OrderService
 * @description The order service for the application
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class OrderService {
    private server: OrderApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new OrderApiClient(config, token);
    }

    async get(workspace: string, item: string) {
        console.log(`service<order>| get(): Enter`);
        console.log(`service<order>| get(): $workspace = ${workspace}`);

        return this.convert(await this.server.get(workspace, item));
    }

    async list(workspace: string) {
        console.log(`service<order>| list(): Enter`);
        console.log(`service<order>| list(): $workspace = ${workspace}`);
        return await this.server.list(workspace);
    }

    async update(workspace: string, item: string, product: any) {
        console.log(`service<order>| update(): Enter`);
        console.log(`service<order>| update(): $workspace = ${workspace}`);
        console.log(`service<order>| update(): $item = ${item}`);
        console.log(
            `service<order>| update(): $order = ${JSON.stringify(product)}`,
        );
        return this.server.update(workspace, item, product);
    }

    async search(orders: number[]) {
        console.log(`service<order>| search(): Enter`);
        console.log(`service<order>| search(): $orders = ${orders}`);

        return this.server.search(orders);
    }

    async attach(workspace: string, item: string, data: any) {
        console.log(`service<order>| attach(): Enter`);
        console.log(`service<order>| attach(): $workspace = ${workspace}`);
        console.log(`service<order>| attach(): $item = ${item}`);
        console.log(
            `service<order>| attach(): $data = ${JSON.stringify(data)}`,
        );

        return this.server.attach(workspace, item, data);
    }

    async getPo(workspace: string, item: string) {
        console.log(`service<order>| getPo(): Enter`);
        console.log(`service<order>| getPo(): $workspace = ${workspace}`);
        console.log(`service<order>| getPo(): $item = ${item}`);

        return this.convert(await this.server.getPo(workspace, item));
    }

    async searchWithDate(workspace: string, request?: SalesOrderSearchRequest) {
        console.log(`service<order>| searchWithDate(): Enter`);
        console.log(
            `service<order>| searchWithDate(): $workspace = ${workspace}`,
        );
        console.log(
            `service<order>| searchWithDate(): $request = ${JSON.stringify(
                request,
            )}`,
        );

        return this.server.searchWithDate(workspace, request);
    }

    async shipped(workspace: string, item: string, data: any) {
        console.log(`service<order>| shipped(): Enter`);
        console.log(`service<order>| shipped(): $workspace = ${workspace}`);
        console.log(`service<order>| shipped(): $item = ${item}`);
        console.log(
            `service<order>| shipped(): $data = ${JSON.stringify(data)}`,
        );

        return this.server.shipped(workspace, item, data);
    }

    async batch(purchases: string[]) {
        console.log(`service<order>| batch(): Enter`);
        console.log(`service<order>| batch(): $purchases = ${purchases}`);

        return this.server.batch(purchases);
    }

    private convert(source: any): SalesOrder {
        console.log(`service<order>| convert(): Enter`);
        console.log(
            `service<order>| convert(): $source = ${JSON.stringify(source)}`,
        );
        const result: SalesOrder = {
            id: source.id,
            assignedTo: source.assignedTo,
            closedOn: source.closedOn,
            createdOn: source.createdOn,
            currency: source.currency,
            customer: source.customer,
            deletedOn: source.deletedOn,
            documents: source.documents,
            deliveredOn: source.deliveredOn,
            externalDataId: source.externalDataId,
            fulfillments: source.fulfillments,
            isReleasedApproved: source.isReleasedApproved,
            items: source.items,
            lastUpdatedOn: source.lastUpdatedOn,
            poExternalRefId: source.poExternalRefId,
            purchaseOrderId: source.purchaseOrderId,
            releasedOn: source.releasedOn,
            rules: source.rules,
            shippedOn: source.shippedOn,
            status: source.status,
            submitsOn: source.submitsOn,
            versions: source.versions,
            workspace: source.workspace,
        };

        return result;
    }
}
