import { UploadFile } from '@ianneo/ui-library';
import { PurchaseApiClient } from '../../infrastructure/clients/purchase.client';
import { PurchaseOrderUpdateDataObject } from '../../infrastructure/hooks/api/purchases/use-update-purchase';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import {
    PurchaseOrderTrace,
    SupplyChainMap,
    SupplyChainMapItem,
} from './../models/purchase.model';
import * as XLSX from 'xlsx';

interface ExcelDataInterface {
    Category: string;
    Component: string;
    Country: string;
    ['External Ref']: string;
    License: string;
    Process: string;
    Subcategory: string;
    Supplier: string;
    Product: string;
}

/**
 * @class
 * @name PurchaseService
 * @description The Purchase service for LFX
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class PurchaseService {
    private server: PurchaseApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new PurchaseApiClient(config, token);
    }

    async create(workspace: string, purchase: any, diversion: string = '0') {
        console.log(`service<purchase>| create(): Enter`);
        console.log(`service<purchase>| create(): $workspace = ${workspace}`);
        console.log(
            `service<purchase>| create(): $data = ${JSON.stringify(purchase)}`,
        );

        return await this.server.create(workspace, purchase, diversion);
    }

    async get(workspace: string, item: string, diversion: string = '0') {
        console.log(`service<purchase>| get(): Enter`);
        console.log(`service<purchase>| get(): $workspace = ${workspace}`);
        console.log(`service<purchase>| get(): $item = ${item}`);
        console.log(
            `service<purchase>| get(): $diversion = ${JSON.stringify(
                diversion,
            )}`,
        );

        return await this.server.get(workspace, item, diversion);
    }

    async list(workspace: string, diversion: string = '0') {
        console.log(`service<purchase>| list(): Enter`);
        console.log(`service<purchase>| list(): $workspace = ${workspace}`);

        return await this.server.list(workspace, diversion);
    }

    async listItems(workspace: string, diversion: string = '0') {
        console.log(`service<purchase>| listItems(): Enter`);
        console.log(
            `service<purchase>| listItems(): $workspace = ${workspace}`,
        );

        return await this.server.listItems(workspace, diversion);
    }

    async update(
        workspace: string,
        item: string,
        purchase: PurchaseOrderUpdateDataObject,
        diversion: string = '0',
    ) {
        console.log(`service<purchase>| update(): Enter`);
        console.log(`service<purchase>| update(): $workspace = ${workspace}`);
        console.log(`service<purchase>| update(): $item = ${item}`);
        console.log(
            `service<purchase>| update(): $data = ${JSON.stringify(purchase)}`,
        );

        return await this.server.update(workspace, item, purchase, diversion);
    }

    async delete(workspace: string, item: string) {
        console.log(`service<purchase>| delete(): Enter`);
        console.log(`service<purchase>| delete(): $workspace = ${workspace}`);
        console.log(`service<purchase>| delete(): $item = ${item}`);

        return await this.server.delete(workspace, item);
    }

    async trace(
        workspace: string,
        item: string,
        diversion: string = '0',
    ): Promise<PurchaseOrderTrace[]> {
        console.log(`service<purchase>| trace(): Enter`);
        console.log(`service<purchase>| trace(): $workspace = ${workspace}`);
        console.log(`service<purchase>| trace(): $item = ${item}`);

        return await this.server.trace(workspace, item, diversion);
    }

    async download(workspace: string, item: string) {
        console.log(`service<purchase>| download(): Enter`);
        console.log(`service<purchase>| download(): $workspace = ${workspace}`);
        console.log(`service<purchase>| download(): $item = ${item}`);

        return await this.server.download(workspace, item);
    }

    async reverse(workspace: string, id: string, diversion: string = '0') {
        console.log(`service<purchase>| reverse(): Enter`);
        console.log(`service<purchase>| reverse(): $workspace = ${workspace}`);
        console.log(`service<purchase>| reverse(): $id = ${id}`);

        return await this.server.reverse(workspace, id, diversion);
    }

    async process(
        purchaseOrderId: string,
        file: UploadFile['originFileObj'],
    ): Promise<SupplyChainMap> {
        const data = await this.import(file);

        console.log(`service<purchase>| process(): $data = ${data}`);

        return {
            parentPurchaseOrder: {
                id: purchaseOrderId,
            },
            mapItems: data,
        } as SupplyChainMap;
    }

    async dispatch(workspace: string, payload: SupplyChainMap) {
        return await this.server.dispatch(workspace, payload);
    }

    async handle(
        workspace: string,
        purchaseOrderId: string,
        file: UploadFile['originFileObj'],
    ) {
        console.log(`service<purchase>| handle(): Enter`);
        console.log(
            `service<purchase>| handle(): $purchaseOrderId = ${purchaseOrderId}`,
        );
        console.log(`service<purchase>| handle(): $file = ${file}`);

        const payload = await this.process(purchaseOrderId, file);
        console.log(`service<purchase>| handle(): $payload = ${payload}`);

        const response = await this.dispatch(workspace, payload);
        console.log(`service<purchase>| handle(): $response = ${response}`);

        return response;
    }

    async import(file: UploadFile['originFileObj']) {
        return new Promise<SupplyChainMapItem[]>(async (resolve, reject) => {
            if (!file) {
                resolve([]); // Return an empty array if no file is provided
                return;
            }

            const fileReader = new FileReader();

            fileReader.readAsArrayBuffer(file);

            fileReader.onload = async (e) => {
                try {
                    const buffer = e.target?.result as ArrayBuffer;
                    const wb = XLSX.read(buffer, { type: 'buffer' });

                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];

                    /* Convert array of arrays */
                    const data: ExcelDataInterface[] =
                        XLSX.utils.sheet_to_json(ws);

                    console.log(
                        `service<purchase>| readFile(): $data = ${data}`,
                    );

                    const responses: SupplyChainMapItem[] = data.map(
                        (x) =>
                            ({
                                supplierCountryCode: x.Country,
                                supplierRegistrationNumber: x.License,
                                supplierName: x.Supplier,
                                orderProcess: x.Process,
                                productCategory: x.Category,
                                productSubCategory: x.Subcategory,
                                productExternalId: x['External Ref'],
                                productName: x.Product,
                            } satisfies SupplyChainMapItem),
                    );

                    resolve(responses); // Resolve the promise with the responses array
                } catch (error) {
                    reject(error); // Reject the promise if there's an error
                }
            };

            fileReader.onerror = (error) => {
                reject(error); // Reject the promise if there's an error
            };
        });
    }

    async listByBatch({ orderIds }: { orderIds: string[] }) {
        console.log(`service<purchase>| listByBatch(): Enter`);
        console.log(`service<purchase>| listByBatch(): $ids = ${orderIds}`);

        return await this.server.listByBatch(orderIds);
    }
}
