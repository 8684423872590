import {
    Button,
    DescriptionItems,
    Descriptions,
    Link,
    Modal,
    Table,
    Tag,
    Text,
} from '@ianneo/ui-library';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    PurchaseComposition,
    PurchaseOrderItem,
    PurchaseOrderLink,
    PurchaseOrderTrace,
    PurchaseOrderVersion,
} from '../../../../../domain/models/purchase.model';
import { useLinkService } from '../../../../../infrastructure/hooks/api/links/use-links-service';
import { useOrderService } from '../../../../../infrastructure/hooks/api/order/use-order-service';
import { useDeletePurchase } from '../../../../../infrastructure/hooks/api/purchases/use-delete-purchase';
import { usePurchaseReverse } from '../../../../../infrastructure/hooks/api/purchases/use-purchase-reverse';
import useAppContext from '../../../../../infrastructure/hooks/use-context.hook';
import useVerifyAdmin from '../../../../../infrastructure/hooks/use-verify-admin';
import { getOrderNature } from '../../../../../infrastructure/utils/order-nature-labeler';
import { ImportBatchPurchaseOrder2 } from '../../import-batch-purchase-order-v2';
import { ImportCustomBatchPurchaseOrder } from '../../import-custom-batch-purchase-order';
import EditPurchaseComponent from '../edit-purchase/edit-purchase-component';
import './purchase-overview.component.css';

interface PurchaseOverviewComponentProps {
    data: PurchaseOrderVersion;
    trace: PurchaseOrderTrace[];
    tier: any[];
}

const PurchaseOverviewComponent: React.FC<PurchaseOverviewComponentProps> = ({
    data,
    trace,
    tier,
}) => {
    const w = useAppContext()?.workspace;
    const { t } = useTranslation();
    const { delegateId } = useParams();
    const context = useAppContext();
    const navigate = useNavigate();
    const { isAdmin } = useVerifyAdmin();
    const [open, setOpen] = useState(false);
    const [importMode, setImportMode] = useState(false);
    const [mode, setMode] = useState<'delete' | 'edit'>();

    const { data: reversed } = usePurchaseReverse(data.owner?.id || '');

    const { mutateAsync: remove } = useDeletePurchase();
    const { service: linkService } = useLinkService();
    const { service: salesService } = useOrderService();

    const cascasdedTiers = useMemo(() => {
        const tiers = trace.map((item) => item.tier || 0);

        return tiers.length > 0 ? Math.max(...tiers) : 0;
    }, [trace]);

    const removePurchase = useCallback(async () => {
        if (data.owner?.parentOrderReference) {
            const existingLinks: PurchaseOrderLink[] =
                await linkService.getByPurchase(
                    data.owner?.workspace?.id || '',
                    data.owner?.id || '',
                );

            const salesOrder = await salesService.getPo(
                data.owner?.workspace?.id || '',
                data.owner?.parentOrderReference || '',
            );

            await Promise.all(
                existingLinks.map(async (x) => {
                    await linkService.delete(
                        x.owner?.workspace?.id || '',
                        salesOrder.id || '',
                        x.owner?.id || '',
                    );
                }),
            );
        }

        await remove({
            id: data.owner?.id || '',
            delegateId,
        });

        navigate('/purchases');
    }, [data, linkService, remove, navigate, delegateId, salesService]);

    const expandedRowRender = (item: PurchaseOrderItem) => {
        const expandedColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                render: (_: unknown, item: PurchaseComposition) => {
                    return item.name?.locales?.find(
                        (x) => x.localeName === 'en',
                    )?.text;
                },
            },
            { title: 'Weight', dataIndex: 'weight' },
        ];

        return (
            <Table
                columns={expandedColumns}
                dataSource={item.purchaseables.compositions}
                scroll={{ x: 'max-content' }}
                style={{ minHeight: 0 }}
                rowKey={'name'}
                pagination={false}
                showColumns={false}
                showFilter={false}
                showSearch={false}
            />
        );
    };

    const getProductName = (source: string | undefined): string => {
        console.log(`getProductName()->source: ${JSON.stringify(source)}`);
        if (!source) {
            return '--';
        } else {
            const sources = source.split('-');
            const retainer = [sources.pop()];
            const base = sources[0];

            if (Number.isNaN(Number.parseInt(base))) {
                return source;
            }

            retainer.unshift(
                ...sources.filter((i) => {
                    console.log(`getProductName()->i: ${i}`);
                    console.log(
                        `getProductName()->i.isNaN: ${JSON.stringify(
                            Number.isNaN(i),
                        )}`,
                    );
                    return Number.isNaN(i);
                }),
            );
            console.log(
                `getProductName()->retainer: ${JSON.stringify(retainer)}`,
            );
            return retainer.join('-');
        }
    };

    const columns = useMemo(
        () => [
            {
                title: t('purchase:detail.overview.productName'),
                dataIndex: ['purchaseables', 'name'],
                render: (_: unknown, item: PurchaseOrderItem) => {
                    const readEnabled = context.permissions?.find(
                        (x) =>
                            x.application === 'io.lfx.t4s.products' &&
                            x.module === 'product' &&
                            x.action === 'read',
                    );

                    return readEnabled ? (
                        <Link
                            onClick={() =>
                                navigate(
                                    delegateId
                                        ? `/products/${item.purchaseables.id}/${delegateId}`
                                        : `/products/${item.purchaseables.id}`,
                                )
                            }
                        >
                            {getProductName(item.purchaseables.name)}
                        </Link>
                    ) : (
                        <Text>{item.purchaseables.name}</Text>
                    );
                },
            },
            {
                title: t('purchase:detail.overview.category'),
                dataIndex: ['purchaseables', 'category'],
                render: (
                    text: string,
                    record: PurchaseOrderItem,
                    index: number,
                ) => {
                    const category = record.purchaseables.category;
                    console.log(`match().category: ${category}`);
                    const match = category?.match(/.*::_-./g);
                    if (match) {
                        console.log(`match(): yes`);
                        let result: string;
                        if (w?.supplyChainLoaderType === 1) {
                            console.log(`match(): is ACTION`);
                            const base = match[0].replace('::_-', '');
                            console.log(`match().base: ${base}`);
                            result = base
                                .split('_')
                                .map(
                                    (t) =>
                                        t[0]?.toUpperCase() +
                                        t.slice(1).toLowerCase(),
                                )
                                .join(' ');
                        } else {
                            result =
                                category
                                    ?.replace(match[0], '')
                                    .split(' ')
                                    .map((t) =>
                                        t
                                            .split('_')
                                            .map(
                                                (t) =>
                                                    t[0].toUpperCase() +
                                                    t.slice(1).toLowerCase(),
                                            )
                                            .join(' '),
                                    )
                                    .join(' ') || '--';
                        }
                        console.log(`match().result: ${result}`);
                        return <>{result}</>;
                    } else {
                        console.log(`match(): no`);
                        return (
                            <>
                                {category
                                    ?.split(' ')
                                    .map((t) =>
                                        t
                                            .split('_')
                                            .map(
                                                (t) =>
                                                    t[0].toUpperCase() +
                                                    t.slice(1).toLowerCase(),
                                            )
                                            .join(' '),
                                    )
                                    .join(' ')}
                            </>
                        );
                    }
                },
            },
            {
                title: t('purchase:detail.overview.comment'),
                render: (
                    text: string,
                    record: PurchaseOrderItem,
                    index: number,
                ) => {
                    console.log(`comment().text: ${text}`);
                    console.log(`comment().record: ${JSON.stringify(record)}`);
                    return <>{record.purchaseables?.description || ''}</>;
                },
            },
            {
                title: t('purchase:detail.overview.price'),
                render: (
                    text: string,
                    record: PurchaseOrderItem,
                    index: number,
                ) => {
                    console.log(`price().text: ${text}`);
                    console.log(`price().record: ${JSON.stringify(record)}`);
                    if (w?.supplyChainLoaderType !== 0) {
                        return <>{record.ppu || ''}</>;
                    } else {
                        return <>--</>;
                    }
                },
                // dataIndex: 'ppu',
            },
            {
                title: t('purchase:detail.overview.quantity'),
                dataIndex: 'quantity',
            },
        ],
        [
            t,
            context.permissions,
            navigate,
            delegateId,
            w?.supplyChainLoaderType,
        ],
    );

    const descriptions = [
        {
            label: t('purchase:detail.overview.supplier'),
            value: data.owner?.supplier?.seller?.name,
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.secondarySupplier'),
            value: data.owner?.supplierProducer?.name,
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.buyDate'),
            value: data.createdOn
                ? new Date(data.createdOn).toLocaleDateString().slice(0, 10)
                : '',
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.deliveryDate'),
            value: data.deliveryDate
                ? new Date(data.deliveryDate).toLocaleDateString().slice(0, 10)
                : '',
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.buyer'),
            value: data.owner?.supplier?.owner?.name,
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.updatedOn'),
            value: data.lastUpdatedOn
                ? new Date(data.lastUpdatedOn).toLocaleDateString().slice(0, 10)
                : '',
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.cascadedTiers'),
            value: cascasdedTiers.toString(),
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.tier'),
            value: tier?.length?.toString(),
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.template'),
            value: data.owner?.rules?.map((x) => x.code).join(','),
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.currency'),
            value: data.owner?.currency,
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.productCount'),
            value: data.manifest?.length.toString(),
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.shippedOn'),
            value: (data.owner?.shippedOn || [])
                .filter((x) => x !== null)
                .map((x) => moment(x).format().split('T')[0])
                .map((x) => <Tag>{x}</Tag>),
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.shipToAddress'),
            value: data.owner?.shipToAddress,
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.orderNature'),
            value: getOrderNature(data?.owner?.orderNature),
            span: 1.5,
        },
    ];

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Descriptions>
                        {descriptions.map((x, i) => (
                            <DescriptionItems
                                key={i}
                                label={x.label}
                                span={x.span}
                            >
                                {x.value}
                            </DescriptionItems>
                        ))}
                    </Descriptions>

                    <div
                        style={{ display: 'flex', gap: '10px' }}
                        hidden={trace.length > 0}
                    >
                        <Button onClick={() => setImportMode(true)}>
                            Upload Mapping
                        </Button>

                        <Button
                            onClick={() => setOpen(true)}
                            hidden={trace.length > 0 || isAdmin}
                        >
                            {t('common:edit')}
                        </Button>

                        <Button
                            onClick={() => setMode('delete')}
                            style={{
                                display:
                                    trace.length > 0 ||
                                    (data.shippedOn || []).length > 0 ||
                                    reversed?.length > 0
                                        ? 'none'
                                        : 'block',
                            }}
                            // hidden={
                            //     trace.length > 0 ||
                            //     !isAdmin ||
                            //     (data.shippedOn || []).length < 1 ||
                            //     reversed?.length > 0
                            // }
                        >
                            Delete
                        </Button>
                    </div>
                </div>

                <Table
                    dataSource={data.manifest}
                    columns={columns}
                    rowKey="id"
                    className="custom-table"
                    showColumns={false}
                    scroll={{ x: 'max-content' }}
                    showSearch={false}
                    showFilter={false}
                    expandable={{
                        expandedRowRender,
                        expandRowByClick: true,
                        rowExpandable: (record: PurchaseOrderItem) => {
                            return (
                                (record.purchaseables.compositions?.length ||
                                    0) > 0
                            );
                        },
                    }}
                />
            </div>

            <EditPurchaseComponent data={data} open={open} setOpen={setOpen} />

            {context.workspace?.supplyChainLoaderType === 0 && (
                <ImportBatchPurchaseOrder2
                    open={importMode}
                    setOpen={setImportMode}
                    purchaseOrder={data}
                />
            )}

            {context.workspace?.supplyChainLoaderType === 1 && (
                <ImportCustomBatchPurchaseOrder
                    open={importMode}
                    setOpen={setImportMode}
                    purchaseOrder={data}
                />
            )}

            {mode === 'delete' && (
                <Modal
                    open={true}
                    title="Delete Purchase Order"
                    okFn={removePurchase}
                    cancelFn={() => setMode(undefined)}
                >
                    <span style={{ fontWeight: 600 }}>
                        Are you sure you want to delete this purchase order?
                    </span>

                    <br />

                    <span
                        style={{
                            fontWeight: 300,
                            color: 'red',
                        }}
                    >
                        * This action cannot be undone
                    </span>
                </Modal>
            )}
        </>
    );
};

export default PurchaseOverviewComponent;
