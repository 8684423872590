import { SolicitationApiClient } from '../../infrastructure/clients/solications.client';
import { SupplierApiClient } from '../../infrastructure/clients/supplier.client';
import { UserApiClient } from '../../infrastructure/clients/user.client';
import { WorkspaceApiClient } from '../../infrastructure/clients/workspace.client';
import { RelationRequester } from '../enums/relation-requester.enum';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { Solicitation } from '../models/solicitation.model';
import { SupplierComment } from '../models/supplier-comment.model';
import { Supplier } from '../models/supplier.model';
import { User } from '../models/user.model';
import { Workspace } from '../models/workspace.model';

export class PartnerService {
    private solicitations: SolicitationApiClient;
    private suppliers: SupplierApiClient;
    private users: UserApiClient;
    private workspaces: WorkspaceApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.solicitations = new SolicitationApiClient(config, token);
        this.suppliers = new SupplierApiClient(config, token);
        this.users = new UserApiClient(config, token);
        this.workspaces = new WorkspaceApiClient(config, token);
    }

    // async convert(source: any): Promise<Supplier> {
    //     console.log(`service<partner>| convert(): Enter`);
    //     console.log(
    //         `service<partner>| convert(): $source = ${JSON.stringify(source)}`,
    //     );
    //     console.log(
    //         `service<partner>| convert(): $source.seller = ${JSON.stringify(
    //             source.seller,
    //         )}`,
    //     );
    //     if (source.seller?.id) {
    //         const workspace = await this.workspaces.get(source.seller.id);
    //         let tags: Map<string, string>;
    //         if (source.tags) {
    //             console.log(
    //                 `service<partner>| convert(): $source.tags = ${JSON.stringify(
    //                     source.tags,
    //                 )}`,
    //             );
    //             if (Array.isArray(source.tags)) {
    //                 tags = new Map<string, string>(
    //                     (source.tags as any[]).map((i) => [i.label, i.value]),
    //                 );
    //             } else {
    //                 tags = new Map(Object.entries(source.tags));
    //             }
    //         } else {
    //             tags = new Map<string, string>();
    //         }

    //         return {
    //             company: workspace,
    //             id: source.id,
    //             contact: source.contact as User,
    //             // location: workspace?.company?.geolocation as GeoLocation,
    //             notes: source.notes,
    //             // tags: tags,
    //             activatedOn: source.activatedOn,
    //             engagedOn: source.engagedOn,
    //             owner: source.owner,
    //             seller: source.seller,
    //         };
    //     } else {
    //         throw new Error(`Unable to load entry`);
    //     }
    // }

    // async addComment(
    //     workspace: string,
    //     source: Supplier,
    //     comment: string,
    //     author: User,
    // ) {
    //     console.log(`service<partner>| addComment(): Enter`);
    //     console.log(
    //         `service<partner>| addComment(): $source = ${JSON.stringify(
    //             source,
    //         )}`,
    //     );
    //     console.log(
    //         `service<partner>| addComment(): $comment = ${JSON.stringify(
    //             comment,
    //         )}`,
    //     );
    //     console.log(
    //         `service<partner>| addComment(): $author = ${JSON.stringify(
    //             author,
    //         )}`,
    //     );
    //     if (source.id) {
    //         const comments = JSON.parse(source.notes ?? '[]');
    //         const entry: SupplierComment = {
    //             userId: author.id,
    //             message: comment,
    //             createdOn: new Date(),
    //         };
    //         comments.push(entry);
    //         source.notes = JSON.stringify(comments);
    //         const result = await this.suppliers.update(
    //             workspace,
    //             source.id,
    //             source,
    //         );
    //         return this.convert(result);
    //     } else {
    //         throw new Error('Supplier is not defined for comment editing');
    //     }
    // }

    async create(
        me: Workspace,
        isNominated: boolean,
        partner: Workspace,
        delegateWorkspace: Workspace,
        party: RelationRequester,
    ) {
        console.log(`service<partner>| create(): Enter`);
        console.log(`service<partner>| create(): $me = ${JSON.stringify(me)}`);
        console.log(
            `service<partner>| create(): $partner = ${JSON.stringify(partner)}`,
        );
        console.log(
            `service<partner>| create(): $party = ${JSON.stringify(party)}`,
        );
        console.log(
            `service<partner>| create(): $isNominated = ${JSON.stringify(
                isNominated,
            )}`,
        );

        return await this.solicitations.create(
            me,
            isNominated,
            partner,
            delegateWorkspace,
        );
    }

    async get(me: Workspace, partner: string) {
        console.log(`service<partner>| get(): Enter`);
        console.log(`service<partner>| get(): $me = ${JSON.stringify(me)}`);
        console.log(
            `service<partner>| get(): $partner = ${JSON.stringify(partner)}`,
        );
        if (me.id) {
            const result = await this.suppliers.get(me.id, partner);
            console.log(
                `service<partner>| get(): $result = ${JSON.stringify(result)}`,
            );
            return result;
        } else {
            throw new Error(`Session is not available. Refresh your browser`);
        }
    }

    async getWallComments(workspace: string, source: Supplier) {
        console.log(`service<partner>| getWallComments(): Enter`);
        console.log(
            `service<partner>| getWallComments(): $source = ${JSON.stringify(
                source,
            )}`,
        );
        console.log(
            `service<partner>| getWallComments(): $source.notes = ${JSON.stringify(
                source.notes,
            )}`,
        );
        if (source.tags) {
            let comments: SupplierComment[] = JSON.parse(source.notes ?? '[]');
            comments = comments.sort((a, b) => {
                if (!a.createdOn || !b.createdOn) return -1;

                return a?.createdOn < b?.createdOn ? 1 : -1;
            });
            return Promise.all(
                comments.map(async (c) => {
                    c.author = (await this.users.get(
                        workspace,
                        c.userId || '',
                    )) as User;
                    return c;
                }),
            );
        } else {
            return [];
        }
    }

    async invite(workspace: string, request: Solicitation) {
        console.log(`service<partner>| invite(): Enter`);
        console.log(
            `service<partner>| invite(): $request = ${JSON.stringify(request)}`,
        );
        return await this.solicitations.invite(workspace, request);
    }

    async list(workspace: string): Promise<Supplier[]> {
        console.log(`service<partner>| list(): Enter`);
        const data: any[] = (await this.suppliers.list(workspace)) as any[];
        console.log(
            `service<partner>| list(): $data = ${JSON.stringify(data)}`,
        );
        // const items: Supplier[] = await Promise.all(
        //     data.map(async (s) => this.convert(s)),
        // );
        return data;
    }

    async remove(workspace: string, supplier: string) {
        console.log(`service<partner>| remove(): Enter`);
        console.log(`service<partner>| remove(): $workspace = ${workspace}`);
        console.log(`service<partner>| remove(): $supplier = ${supplier}`);
        return this.suppliers.delete(workspace, supplier);
    }
}
