import { OrderNatureType } from '../../../../domain/enums/order-nature.enum';
import { UnitType } from '../../../../domain/enums/unit-type.enum';
import { ProductCategory } from '../../../../domain/models/category.model';
import {
    PurchaseOrderTrace,
    PurchaseOrderVersion,
} from '../../../../domain/models/purchase.model';
import { Supplier } from '../../../../domain/models/supplier.model';
import { Workspace } from '../../../../domain/models/workspace.model';
import { useCategoryService } from '../../../../infrastructure/hooks/api/category/use-category-service';
import { useMeService } from '../../../../infrastructure/hooks/api/me/use-me-service';
import { usePartnerConnect } from '../../../../infrastructure/hooks/api/partner/use-partner-create';
import { usePartnerInvite } from '../../../../infrastructure/hooks/api/partner/use-partner-invite';
import { usePartnerService } from '../../../../infrastructure/hooks/api/partner/use-partner-service';
import { useCreateProduct } from '../../../../infrastructure/hooks/api/products/use-create-product';
import { useProductService } from '../../../../infrastructure/hooks/api/products/use-product-service';
import { useCreatePurchase } from '../../../../infrastructure/hooks/api/purchases/use-create-purchase';
import { usePurchaseService } from '../../../../infrastructure/hooks/api/purchases/use-purchase-service';
import { useWorkspaceService } from '../../../../infrastructure/hooks/api/workspaces/use-workspace-service';
import { useAlertContext } from '../../../../infrastructure/hooks/use-alert.hook';
import { read, utils } from 'xlsx';

interface SheetData {
    'PO Factory Name'?: string;
    'PO Factory License Number'?: string;
    'PO Factory Country'?: string;
    'PO Factory Address'?: string;
    'PO Factory Contact Name'?: string;
    'PO Factory Contact Email'?: string;
    'Main Category'?: string;
    'PO Number'?: string;
    'Item Number'?: string;
    'Product Description'?: string;
    'In-House / Sub-Contract / Component / Raw Material': string;
    'Production Process': string;
    Subcategory: string;
    Component: string;
    'Raw Material': string;
    'Factory Name': string;
    'Factory License Number': string;
    'Factory Country': string;
    'Factory Address': string;
    'Factory Contact First Name'?: string;
    'Factory Contact Last Name'?: string;
    'Factory Contact Email'?: string;
    __rowNum__: number;
    [key: string]: string | number | undefined;
}

enum CustomOrderNature {
    PROCESSING_INHOUSE = 'Production - In-house',
    PROCESSING_OUTSOURCE = 'Production - Outsource',
    COMPONENT = 'Component',
    RAW_MATERIAL = 'Raw Material',
}

const sheetName = 'PPMF Form Section B';

interface SupplierWithExtras extends Supplier {
    workspaceId: string;
    supplierId: string;
}

export function useImportActionTemplate() {
    const { setAlert } = useAlertContext();
    const { service: partnerService } = usePartnerService();
    const { service: workspaceService } = useWorkspaceService();
    const { service: productService } = useProductService();
    const { service: categoryService } = useCategoryService();
    const { service: purchaseService } = usePurchaseService();
    const { service: meService } = useMeService({});

    const { mutateAsync: invitePartner } = usePartnerInvite();
    const { mutateAsync: connectPartner } = usePartnerConnect();
    const { mutateAsync: createProduct } = useCreateProduct();
    const { mutateAsync: createPurchase } = useCreatePurchase();

    const delay = (duration: number) =>
        new Promise((resolve) => setTimeout(resolve, duration));

    const validateExcel = async (items: SheetData[]) => {
        const uniquePoFactory = Array.from(
            new Set(items.map((item) => item['PO Factory Name'])),
        );

        if (uniquePoFactory.length > 1) {
            throw new Error(
                'The PO Factory Name must be the same for all items',
            );
        }

        const requiredFields = [
            'Factory Name',
            'Factory License Number',
            'Factory Country',
            'Factory Address',
            'In-House / Sub-Contract / Component / Raw Material',
        ];

        const validationErrors: string[] = [];

        items.forEach(async (item) => {
            const requiredError = requiredFields.some(
                (field) => !item[field] || item[field] === '',
            );

            if (requiredError) {
                validationErrors.push(
                    `Row ${item.__rowNum__} is missing required fields for ${
                        item['Factory Name']
                    }: ${requiredFields.join(', ')}`,
                );

                return;
            }

            if (
                item['PO Factory Name'] &&
                !(
                    item['PO Factory Country'] &&
                    item['PO Factory License Number']
                )
            ) {
                validationErrors.push(
                    `Row ${item.__rowNum__} is missing required fields for ${item['PO Factory Name']}: PO Factory License Number, PO Factory Country`,
                );

                return;
            }

            if (
                item['Factory Contact First Name'] &&
                !(
                    item['Factory Contact Last Name'] &&
                    item['Factory Contact Email']
                )
            ) {
                validationErrors.push(
                    `Row ${item.__rowNum__} is missing required field for ${item['Factory Name']}: Factory Contact Last Name`,
                );

                return;
            }
        });

        if (validationErrors.length > 0) {
            throw new Error(validationErrors[0]);
        }
    };

    const findPartner = async (workspaceId: string, name: string) => {
        const partners = await partnerService.list(workspaceId);

        const existingPartner = partners.find((partner) => {
            return (
                partner.seller?.companyName?.toLocaleLowerCase() ===
                name?.toLocaleLowerCase()
            );
        });

        console.log(
            `test-ppmf: existingPartner = ${JSON.stringify(existingPartner)}`,
        );

        const response = existingPartner
            ? {
                  ...existingPartner,
                  workspaceId: existingPartner?.seller?.id || '',
                  supplierId: existingPartner?.id || '',
              }
            : undefined;

        console.log(`test-ppmf: response = ${JSON.stringify(response)}`);

        return response;
    };

    const createPurchaseOrder = async (
        workspace: string,
        supplier: string,
        item: SheetData,
        product: { id: string; cost: number },
        purchaseOrder: PurchaseOrderVersion,
        parentPurchase?: PurchaseOrderVersion,
        type: 'poFactory' | 'factory' = 'factory',
    ) => {
        const getCustomNature = (orderNature: string) => {
            switch (orderNature) {
                case 'Component':
                    return OrderNatureType.COMPONENT;
                case 'Raw Material':
                    return OrderNatureType.RAW_MATERIAL;
                case 'Production - In-house':
                    return OrderNatureType.PROCESSING;
                case 'Production - Outsource':
                    return OrderNatureType.OUTSOURCE;
                default:
                    return OrderNatureType.COMPONENT;
            }
        };

        const purchase = await createPurchase({
            supplier,
            currency: purchaseOrder.owner?.currency || 'USD',
            items: [
                {
                    material: product.id,
                    comment: 'Cloned',
                    quantity: 1,
                    ppu: product.cost,
                    unit: UnitType.PIECES,
                },
            ],
            // To be reviewed, not sure if need to create the ruleset for the purchase order
            rules: purchaseOrder.owner?.rules?.[0]?.id
                ? [purchaseOrder.owner?.rules?.[0].id]
                : [],
            orderNature:
                type === 'poFactory'
                    ? purchaseOrder.owner?.orderNature
                    : getCustomNature(
                          item[
                              'In-House / Sub-Contract / Component / Raw Material'
                          ],
                      ),
            workspaceId: workspace,
            parentOrderReference: parentPurchase?.owner?.id,
        });

        const version = await purchaseService.get(workspace, purchase.id);

        return version;
    };

    const findOrCreatePartner = async (
        id: string,
        vendorName: string,
        mode: 'poFactory' | 'factory',
        data: SheetData,
        purchaseOrder: PurchaseOrderVersion,
        workspace: Workspace,
    ) => {
        let parentWorkspace;
        const isPartner = mode === 'poFactory';

        const workspaceId = isPartner
            ? purchaseOrder.owner?.supplier?.seller?.id || ''
            : id;

        // Check if the partner already exists
        const existingPartner = await findPartner(workspaceId, vendorName);

        if (existingPartner) {
            return existingPartner;
        }

        if (mode === 'factory') {
            const existingVendors = await workspaceService.getByCompanyName(
                data['PO Factory Name']?.toString()?.trim() || '',
            );

            parentWorkspace = existingVendors?.[0] || workspace;
        }

        const workspaces = await workspaceService.getByCompanyName(vendorName);

        if (workspaces.length > 0) {
            const matchingWorkspace = workspaces.find(
                (w) => w.displayName === vendorName,
            );
            await connectPartner({
                partnerWorkspace: matchingWorkspace,
                supplierWorkspace:
                    mode === 'factory'
                        ? parentWorkspace
                        : purchaseOrder.owner?.supplier?.seller,
                delegateWorkspace: workspace,
            });
        } else {
            const contact = [];

            const user = await meService?.getUser();

            if (user) {
                contact.push({
                    email: user.email?.toString()?.trim(),
                    firstName: user.firstName?.toString()?.trim(),
                    lastName: user.lastName?.toString()?.trim(),
                });
            }

            if (isPartner && data['PO Factory Contact Name']) {
                const [firstName, lastName] =
                    data['PO Factory Contact Name'].split(' ');

                contact.push({
                    firstName: firstName?.toString()?.trim(),
                    lastName: lastName?.toString()?.trim(),
                    email: data['PO Factory Contact Email']?.toString()?.trim(),
                });
            } else if (
                !isPartner &&
                data['Factory Contact First Name']?.toString()?.trim()
            ) {
                contact.push({
                    firstName: data['Factory Contact First Name']
                        ?.toString()
                        ?.trim(),
                    lastName:
                        data['Factory Contact Last Name']?.toString()?.trim() ||
                        '',
                    email: data['Factory Contact Email']?.toString()?.trim(),
                });
            }

            await invitePartner({
                solicitation: {
                    workspaceId: workspaceId,
                    company: isPartner
                        ? data['PO Factory Name']?.toString()?.trim()
                        : data['Factory Name']?.toString()?.trim(),
                    registrationNumber: isPartner
                        ? data['PO Factory License Number']?.toString()?.trim()
                        : data['Factory License Number']?.toString()?.trim(),
                    country: isPartner
                        ? data['PO Factory Country']?.toString()?.trim()
                        : data['Factory Country']?.toString()?.trim(),
                    contact: contact.length > 0 ? contact : undefined,
                    isNominated: false,
                    address: isPartner
                        ? data['PO Factory Address']?.toString()?.trim()
                        : data['Factory Address']?.toString()?.trim(),
                    delegate: [
                        {
                            delegate: isPartner ? workspace?.id : workspaceId,
                        },
                    ],
                },
            });
        }
        await delay(2000);

        console.log(`test-ppmf: workspaceId = ${JSON.stringify(workspaceId)}`);
        console.log(`test-ppmf: vendorName = ${JSON.stringify(vendorName)}`);

        const partner = await findPartner(workspaceId, vendorName);
        console.log(`test-ppmf: partner = ${JSON.stringify(partner)}`);

        if (!partner) {
            throw new Error('Failed to create partner');
        }

        return partner;
    };

    const findOrCreateCategory = async (
        id: string,
        item: SheetData,
        parentCode?: string,
        parentWorkspace?: string,
    ) => {
        let parentCategory: ProductCategory | undefined;
        let categoryName =
            item.Subcategory?.toString()?.trim() || 'Placeholder Category';

        if (parentCode && parentWorkspace) {
            const parentProduct = await productService.get(
                parentWorkspace,
                parentCode,
            );

            if (parentProduct) {
                parentCategory = parentProduct.product?.category;
            }
        }

        let category = await categoryService.searchByCode(
            id,
            encodeURIComponent(
                parentCategory?.code || categoryName?.toString()?.trim(),
            ),
        );

        if (!category.id) {
            category = await categoryService.create(id, {
                code: parentCategory?.code || categoryName?.toString()?.trim(),
                unit: parentCategory?.unit || UnitType.PIECES,
                name: parentCategory?.name || {
                    locales: [{ localeName: 'en', text: categoryName }],
                },
                description: parentCategory?.description || {
                    locales: [{ localeName: 'en', text: '' }],
                },
            });
        }

        return category;
    };

    const findOrCreateProduct = async (
        workspaceId: string,
        item: SheetData,
        categoryId: string,
    ) => {
        let parsedName = '';
        const orderNature = item[
            'In-House / Sub-Contract / Component / Raw Material'
        ] as CustomOrderNature;

        const nameMapping = {
            [CustomOrderNature.COMPONENT]: 'Component',
            [CustomOrderNature.RAW_MATERIAL]: 'Raw Material',
            [CustomOrderNature.PROCESSING_OUTSOURCE]: 'Production Process',
            [CustomOrderNature.PROCESSING_INHOUSE]: 'Production Process',
        };

        if (
            orderNature === CustomOrderNature.PROCESSING_INHOUSE ||
            orderNature === CustomOrderNature.PROCESSING_OUTSOURCE
        ) {
            parsedName = `Service - ${item['Production Process']}`;
        } else {
            const mapping = nameMapping[orderNature];
            parsedName = (item[mapping] as string).toString() || '';
        }

        let product = await productService.search(
            workspaceId,
            encodeURIComponent(
                parsedName.toString().toLocaleUpperCase().trim(),
            ),
        );

        if (!product) {
            product = await createProduct({
                workspaceId,
                product: {
                    category: categoryId,
                    externalDataId: parsedName.toLocaleUpperCase().trim(),
                    name: parsedName.toLocaleUpperCase().trim(),
                    description: '',
                    unit: UnitType.PIECES,
                },
            });

            await delay(1500);
        }

        return product;
    };

    const prepareExcel = async (
        items: SheetData[],
        id: string,
        purchaseOrderId: string,
    ) => {
        const workspace: Workspace = await workspaceService.get(id, '1');
        const purchaseOrder: PurchaseOrderVersion = await purchaseService.get(
            workspace?.id || '',
            purchaseOrderId,
            '1',
        );

        let parentPurchaseOrder = purchaseOrder;
        let poFactoryPartner: SupplierWithExtras | undefined;
        let parentOrderTrace: PurchaseOrderTrace | undefined;
        let poFactoryParentPurchaseOrder: PurchaseOrderVersion | undefined;

        // Check if there was a previous trace.
        const trace = await purchaseService.trace(
            workspace.id || '',
            purchaseOrder.owner?.id || '',
            '1',
        );

        if (trace.length > 0) {
            parentPurchaseOrder = await purchaseService.get(
                trace?.[0].workspaceId || '',
                trace?.[0].id || '',
                '1',
            );
        }

        for (const item of items.filter((x) => x['PO Factory Name'])) {
            if (!poFactoryPartner) {
                // Creates the PO Factory
                poFactoryPartner = await findOrCreatePartner(
                    workspace?.id || '',
                    item['PO Factory Name']?.toString()?.trim() || '',
                    'poFactory',
                    item,
                    parentPurchaseOrder,
                    workspace,
                );
            }

            if (!parentOrderTrace) {
                const traces = await purchaseService.trace(
                    workspace?.id || '',
                    purchaseOrder.owner?.id || '',
                    '1',
                );

                const parentTrace = traces.find(
                    (x) =>
                        x.supplier?.owner?.companyName ===
                        parentPurchaseOrder.owner?.supplier?.owner?.companyName,
                );

                parentOrderTrace = parentTrace;

                if (parentTrace) {
                    poFactoryParentPurchaseOrder = await purchaseService.get(
                        parentPurchaseOrder.owner?.supplier?.owner?.id || '',
                        parentTrace.id || '',
                    );
                }
            }

            if (!poFactoryParentPurchaseOrder) {
                // Creates the Parent PO for the PO Factory using the first manifest item of the PO
                const purchase = await createPurchaseOrder(
                    purchaseOrder.owner?.supplier?.seller?.id || '',
                    poFactoryPartner.supplierId,
                    item,
                    {
                        id: purchaseOrder.manifest?.[0].purchaseables.id || '',
                        cost: Number(purchaseOrder.manifest?.[0].ppu || 0),
                    },
                    purchaseOrder,
                    purchaseOrder,
                    'poFactory',
                );

                poFactoryParentPurchaseOrder = purchase;
            }
        }

        let purchase;
        console.log(`test-ppmf: total items = ${items.length}`);

        for (let index = 0; index < items.length; index++) {
            console.log(`test-ppmf: index = ${index}`);
            const item = items[index];
            const workspaceId = item['PO Factory Name']
                ? poFactoryPartner?.workspaceId || ''
                : workspace?.id || '';

            const factoryPartner = await findOrCreatePartner(
                workspaceId,
                item['Factory Name'] || '',
                'factory',
                item,
                parentPurchaseOrder,

                workspace,
            );
            delay(250);

            const category = await findOrCreateCategory(workspaceId, item);

            // Creates the product with category info into the Factory Workspace
            const product = await findOrCreateProduct(
                workspaceId,
                item,
                category.id || '',
            );
            await delay(750);

            const parentPurchase = item['PO Factory Name']
                ? poFactoryParentPurchaseOrder
                : parentPurchaseOrder;
            console.log(
                `test-ppmf: parent-puchase = ${JSON.stringify(
                    parentPurchase?.id,
                )}`,
            );

            purchase = await createPurchaseOrder(
                workspaceId,
                factoryPartner.supplierId,
                item,
                {
                    id: product.id || '',
                    cost: product.versions?.[0].cost || 0,
                },
                purchaseOrder,
                parentPurchase,
                'factory',
            );
            console.log(`test-ppmf: purchase = ${JSON.stringify(purchase.id)}`);
        }
    };

    const submit = async (id: string, purchaseOrderId: string, file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);

            reader.onload = async (e) => {
                try {
                    const buffer = e.target?.result as ArrayBuffer;
                    const wb = read(buffer, { type: 'array', cellDates: true });

                    const ws = wb.Sheets[sheetName];
                    const sheetData: SheetData[] = utils.sheet_to_json(ws);

                    const filteredSheetData = sheetData.filter((data) =>
                        data['Factory Name']?.toString()?.trim(),
                    );

                    await validateExcel(filteredSheetData);

                    await prepareExcel(filteredSheetData, id, purchaseOrderId);

                    resolve([]);

                    setAlert({
                        type: 'success',
                        message:
                            'Purchase Order imported successfully, please refresh the page.',
                        title: 'Import Purchase Order',
                    });
                } catch (err) {
                    const error = err as Error;

                    setAlert({
                        type: 'error',
                        message: `Failed to import purchase order: ${error.message}`,
                        title: 'Import Purchase Order',
                    });

                    reject(error);
                }
            };

            reader.onerror = (err) => {
                reject(err);
            };
        });
    };

    return { submit };
}
