import { useQuery } from '@tanstack/react-query';
import { Workspace } from '../../../../domain/models/workspace.model';
import { PartnerService } from '../../../../domain/services/partner.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePartnerService } from './use-partner-service';

interface UsePartnerInterface {
    id?: string;
    delegatedWorkspace?: Workspace;
    enabled?: boolean;
}

const getPartner = async (
    service: PartnerService,
    workspace: Workspace,
    id: string,
): Promise<any> => {
    const response = await service.get(workspace, id);

    return response;
};

export function usePartner({
    id,
    delegatedWorkspace,
    enabled = true,
}: UsePartnerInterface) {
    const context = useAppContext();
    const { service } = usePartnerService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'partner',
            { workspace: delegatedWorkspace?.id || context.workspace?.id, id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return;
            return await getPartner(
                service,
                delegatedWorkspace || context.workspace,
                id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch partner information: ${error.message}`,
            });
        },
        enabled: enabled,
    });
}
