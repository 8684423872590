import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Solicitation } from '../../../../domain/models/solicitation.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePartnerService } from './use-partner-service';

export interface LinkProductMaterialParams {
    solicitation: Solicitation;
}

export function usePartnerInvite() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = usePartnerService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ solicitation }: LinkProductMaterialParams) => {
            if (!context.workspace?.id || !solicitation) return;

            return await service.invite(
                solicitation.workspaceId || '',
                solicitation,
            );
        },
        onSuccess: (_, args) => {
            setTimeout(() => {
                client.invalidateQueries({
                    queryKey: ['partners'],
                });

                client.invalidateQueries({
                    queryKey: ['workspaces'],
                });

                client.invalidateQueries({
                    queryKey: ['delegations', 'list'],
                });
            }, 1500);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to invite partner: ${error.message}`,
                title: 'Invite Partner',
            });
        },
    });
}
