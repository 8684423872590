import {
    CompositionCreateData,
    CreateProduct,
    Product,
} from '../../domain/models/product.model';
import { ServiceConfigInterface } from './../../domain/interfaces/config.context.interface';
import { ApiServer } from './api.server';

export class ProductApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'products', 'products');
    }

    create(workspace: string, product: CreateProduct) {
        console.log(`client<product>| create(): Enter`);
        console.log(`client<product>| create(): $workspace = ${workspace}`);
        return super.post('create', workspace, product);
    }

    get(workspace: string, item: string) {
        console.log(`client<product>| get(): Enter`);
        console.log(`client<product>| get(): $workspace = ${workspace}`);
        return super.get('get', workspace, item);
    }

    list(workspace: string) {
        console.log(`client<product>| list(): Enter`);
        console.log(`client<product>| list(): $workspace = ${workspace}`);
        return super.get('list', workspace);
    }

    listVersion(workspace: string, version: string) {
        console.log(`client<product>| listVersion(): Enter`);
        console.log(
            `client<product>| listVersion(): $workspace = ${workspace}`,
        );
        return super.get('listVersion', workspace, version);
    }

    update(workspace: string, item: string, product: Product) {
        console.log(`client<product>| update(): Enter`);
        console.log(`client<product>| update(): $workspace = ${workspace}`);
        console.log(`client<product>| update(): $item = ${item}`);
        console.log(
            `client<product>| update(): $product = ${JSON.stringify(product)}`,
        );
        return super.patch('update', workspace, item, product);
    }

    compose(workspace: string, item: string, material: CompositionCreateData) {
        console.log(`client<product>| componse(): Enter`);
        console.log(`client<product>| compose(): $workspace = ${workspace}`);
        return super.post('compose', workspace, item, material);
    }

    delete(workspace: string, item: string) {
        console.log(`client<product>| delete(): Enter`);
        console.log(`client<product>| delete(): $workspace = ${workspace}`);
        console.log(`client<product>| delete(): $item = ${item}`);
        return super.delete('delete', workspace, item);
    }

    search(workspace: string, name: string) {
        console.log(`client<product>| search(): Enter`);
        console.log(`client<product>| search(): $workspace = ${workspace}`);
        console.log(`client<product>| search(): $name = ${name}`);
        return super.get('search', workspace, name);
    }
}
